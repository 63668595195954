<template>
  <b-container fluid>
    <bo-page-title />

    <BoUserForm :row.sync="row" :is-account-setting="true" v-bind="passToSubComp" />

  </b-container>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'
import BoUserForm from './BoUser/Form.vue'
const _ = global._

export default {
  extends: GlobalVue,
  components:{BoUserForm},
  data() {
    return {
      idKey:'bu_id',
      statusKey:'bu_is_active',
      mrLevel:[],
    }
  },
  methods:{
    getProfile(){
       this.loadingOverlay = true
      Gen.apiRest(
        "/get/Users/"+this.user.id, 
        {
          params: Object.assign({}, this.apiParams)
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        this.setFocusForm()
      }).catch(()=>{
        this.loadingOverlay = false
      })
    }
  },
  mounted(){
    this.getProfile()
  },
  watch:{
    $route(){
      this.getProfile()
    },
  }
}
</script>